@tailwind base;
@tailwind components;
@tailwind utilities;

/* kanban styles */

.drag-card {
  @apply opacity-100 !important;
  @apply rotate-6;
}

.ghost-card {
  @apply bg-gray-100/40 dark:bg-gray-600/40 !important;
}

/* calendar styles */

.fc .fc-toolbar {
  @apply flex-row-reverse justify-end px-4 !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  @apply mb-5 !important;
}

.fc .fc-toolbar-title {
  @apply text-lg text-gray-900 font-semibold !important;
}

.fc .fc-today-button {
  @apply rounded-lg border border-gray-200 bg-white text-sm font-medium px-4 py-2 text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-2 focus:ring-primary-700 focus:text-primary-700 !important;
}

.fc-direction-ltr .fc-toolbar>*> :not(:first-child) {
  @apply mx-2 !important;
}

.fc .fc-button-group .fc-prev-button,
.fc .fc-button-group .fc-next-button {
  @apply bg-white border-0 text-gray-500 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 rounded inline-flex focus:bg-gray-100 focus:ring-1 focus:ring-gray-100 justify-center !important;
}

.fc .fc-scrollgrid {
  @apply border-l-0 border-gray-200 !important;
}

.fc .fc-daygrid-day-frame {
  @apply border-gray-200 !important;
}

.fc .fc-col-header-cell-cushion {
  @apply py-3 text-base text-gray-900 font-semibold !important;
}

.fc-theme-standard th {
  @apply border-0 border-b border-gray-200 !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end {
  @apply mr-2 !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start {
  @apply ml-2 !important;
}

.fc .fc-event .fc-event-main {
  @apply p-2 bg-primary-700 hover:bg-primary-800 !important;
}

.fc .fc-h-event .fc-event-main-frame {
  @apply text-xs font-semibold !important;
}

.fc .fc-daygrid-day-frame {
  @apply hover:bg-gray-50 cursor-pointer !important;
}

/* feed styles */

@media (min-width: 1280px) {
  .feed-container {
    height: calc(100vh - 4rem);
  }
}

/* Cookie policy banner styles */

#cookie-policy {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  z-index: 100;
}

#cookie-policy .cookie-container {
  border-radius: 10px;
  max-width: 400px;
  opacity: 0.95;
  margin: 30px;
  padding: 30px;
}

#cookie-policy .cookie-container {
  background: #181818;
  color: #fff;
}

@media (max-width: 767px) {
  #cookie-policy .cookie-container {
    max-width: unset;
    width: 100%;
    margin: 0;
    border-radius: 10px 10px 0 0;
  }
}

#cookie-policy .cookie-container .cookie-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  padding-top: 20px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

#cookie-policy .cookie-container .cookie-actions .cookie-more {
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
}

#cookie-policy .cookie-container .cookie-actions .cookie-more {
  color: #fff;
  background: #303037;
}

#cookie-policy .cookie-container .cookie-actions .cookie-agree {
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
}

#cookie-policy .cookie-container .cookie-actions .cookie-agree {
  color: #fff;
  background: #303037;
}
